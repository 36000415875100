import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function NavBar() {
    const { currentUser } = useAuth();
    const isLoggedIn = !!currentUser;

    // Reference to the collapse element
    const navbarCollapseRef = useRef(null);

    // Function to collapse the navbar
    const handleLinkClick = () => {
        if (navbarCollapseRef.current) {
            navbarCollapseRef.current.classList.remove('show');
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to="/">FindABizAfrica</Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav" ref={navbarCollapseRef}>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about-us" onClick={handleLinkClick}>About Us</Link>
                        </li>

                        {isLoggedIn ? (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard" onClick={handleLinkClick}>Dashboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/business-details" onClick={handleLinkClick}>Register Business</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/businesstips" onClick={handleLinkClick}>Business Tips</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/logout" onClick={handleLinkClick}>Logout</Link>
                                </li>
                            </>
                        ) : (
                            <li className="nav-item">
                                <Link className="nav-link" to="/business-owner-portal" onClick={handleLinkClick}>Business Owners</Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
