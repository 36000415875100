import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import API_URL from '../config/config';

function Dashboard() {
    const [listings, setListings] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${API_URL}/api/dashboard`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch dashboard data');
            }
            return response.json();
        })
        .then(data => {
            setListings(data.listings);
        })
        .catch(err => {
            console.error('Error loading dashboard:', err);
            setError(err.message || 'Unknown error');
        });
    }, []);

    const handleEdit = (id) => {
        navigate(`/business/edit/${id}`);
    };

    const handleMetricsClick = (id) => {
        navigate(`/business/${id}/analytics`);
    };

    return (
        <div className="container mt-5">
            <h1>Business Dashboard</h1>
            {error && <p className="alert alert-danger">{error}</p>}

            <div className="register-business-section">
                <h2>Register Your Business</h2>
                <p>
                    If you have not registered your business, please <Link to="/business/new">click here</Link> to do so.
                </p>
            </div>

            <section className="get-started-section">
                <h2>New to Find A Biz Africa?</h2>
                <p>Learn how to create a great listing and get the most out of our platform.</p>
                <Link to="/businesstips" className="btn btn-primary">Get Started</Link>
            </section>

            {listings.length > 0 ? (
                <div>
                    {listings.map(listing => (
                        <div key={listing.id}>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h2>Analytics Dashboard</h2>
                                    <p>Track how many times your business was searched for and clicked on:</p>
                                    <ul className="list-group">
                                        <li className="list-group-item"><strong>Search Views:</strong> {listing.search_views}</li>
                                        <li className="list-group-item"><strong>Clicks:</strong> {listing.clicks}</li>
                                        <li className="list-group-item"><strong>Website Clicks:</strong> {listing.website_clicks}</li>
                                        <li className="list-group-item"><strong>Facebook Clicks:</strong> {listing.facebook_clicks}</li>
                                        <li className="list-group-item"><strong>Instagram Clicks:</strong> {listing.instagram_clicks}</li>
                                        <li className="list-group-item"><strong>TikTok Clicks:</strong> {listing.tiktok_clicks}</li>
                                        <li className="list-group-item"><strong>Twitter Clicks:</strong> {listing.twitter_clicks}</li>
                                        <li className="list-group-item"><strong>WhatsApp Clicks:</strong> {listing.whatsapp_clicks}</li>
                                    </ul>
                                    <p className="mt-3">Search Views indicate how many times your business appeared in search results. Clicks represent the number of times users clicked on your business to view more details.</p>
                                    <button className="btn btn-primary" onClick={() => handleMetricsClick(listing.id)}>View Monthly Metrics</button>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    <h2>Edit Business Details</h2>
                                    <p>Update your business details here:</p>
                                    <div>
                                        <h4>{listing.name}</h4>
                                        <p>{listing.description}</p>
                                        <button className="btn btn-primary" onClick={() => handleEdit(listing.id)}>Edit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No listings available. Add your business today!</p>
            )}
            <div className="mt-5">
                <h2>Data Policy</h2>
                <p>For more details on how we handle your data, please refer to our <Link to="/data-policy">Data Policy</Link>.</p>
            </div>
        </div>
    );
}

export default Dashboard;
