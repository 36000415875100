import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from './MetaTags';

function BusinessOwnerPortal() {
    return (
        <div className="container mt-5">
            <MetaTags
                title="Find A Biz Africa - Your African Business Directory"
                description="Discover and connect with African businesses. Find local services, products, and companies across the African continent."
                keywords="African businesses, business directory, Africa, find companies, local services, African products"
            />
            <h1>Welcome Business Owners!</h1>
            <p>Join our platform to reach more customers and expand your business. </p> 
            <p>Sign up today to list your business or log in to manage your listings.</p>
            <ul>
                <li><Link to="/login">Log In</Link></li>
                <li><Link to="/signup">Sign Up</Link></li>
                <li><Link to="/request-password-reset">Forgot Password?</Link></li>
                <li><Link to="/faqs">FAQs</Link></li>
            </ul>
        </div>
    );
}

export default BusinessOwnerPortal;
