import React, { useState } from 'react';
import API_URL from '../config/config'; // Adjust the path as necessary
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function BusinessListings() {
  const [businesses, setBusinesses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerms, setSearchTerms] = useState({
    keywords: '',
    exactKeywords: false,
    name: '',
    exactName: false,
    country: '',
    state: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [infoMessage, setInfoMessage] = useState('');

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchTerms(prevTerms => ({ ...prevTerms, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSearchTerms(prevTerms => ({ ...prevTerms, [name]: checked }));
  };

  const searchBusinesses = (page = 1) => {
    const query = Object.keys(searchTerms)
      .filter(key => searchTerms[key] !== '' && searchTerms[key] !== false)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(searchTerms[key])}`)
      .join('&') + `&page=${page}`;

    setIsLoading(true);
    setBusinesses([]); // Clear previous results
    setTotalPages(0); // Reset total pages
    setCurrentPage(page); // Set to the new search page

    fetch(`${API_URL}/api/search?${query}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.data && data.data.length > 0) {
          setBusinesses(data.data);
          setTotalPages(data.numberOfPages || 0);
          setCurrentPage(page);
          setInfoMessage(data.message || '');
        } else {
          setBusinesses([]);
          setTotalPages(0);
          setInfoMessage(data.message || 'No businesses found.');
        }
        setIsLoading(false);
        setError('');
        setHasSearched(true);
      })
      .catch(error => {
        console.error('Error loading businesses:', error);
        setError('Failed to load businesses. Please try again.');
        setIsLoading(false);
        setBusinesses([]);
      });
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    if (!searchTerms.keywords && !searchTerms.name && !searchTerms.country && !searchTerms.state) {
      setError('Please enter at least one search term.');
      return;
    }

    setError(''); // Clear previous error if any
    searchBusinesses(1); // Start from page 1 for every new search
  };

  const toggleDetails = (id) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      fetch(`${API_URL}/api/business/${id}`)
        .then(response => response.json())
        .then(data => {
          const updatedBusinesses = businesses.map(business =>
            business.id === id ? { ...business, ...data } : business
          );
          setBusinesses(updatedBusinesses);
          setExpandedId(id);
        })
        .catch(error => {
          console.error('Failed to load detailed business data:', error);
        });
    }
  };

  const handleExternalClick = (id, type) => {
    fetch(`${API_URL}/api/business/${id}/external-click`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ type })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to track external click');
      }
    })
    .catch(error => {
      console.error('Error tracking external click:', error);
    });
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSearchSubmit} className="row g-3 mb-4">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control mb-2"
            name="keywords"
            placeholder="Keywords (e.g., coffee, bakery)"
            value={searchTerms.keywords}
            onChange={handleSearchChange}
          />
          <label>
            <input
              type="checkbox"
              name="exactKeywords"
              checked={searchTerms.exactKeywords}
              onChange={handleCheckboxChange}
            />
            Exact match
            <i
              className="fas fa-question-circle"
              data-bs-toggle="modal"
              data-bs-target="#infoModalKeywords"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
            ></i>
          </label>
        </div>

        <div className="col-md-4">
          <input
            type="text"
            className="form-control mb-2"
            name="name"
            placeholder="Business name"
            value={searchTerms.name}
            onChange={handleSearchChange}
          />
          <label>
            <input
              type="checkbox"
              name="exactName"
              checked={searchTerms.exactName}
              onChange={handleCheckboxChange}
            />
            Exact match
            <i
              className="fas fa-question-circle"
              data-bs-toggle="modal"
              data-bs-target="#infoModalName"
              style={{ marginLeft: '5px', cursor: 'pointer' }}
            ></i>
          </label>
        </div>

        <div className="col-md-4">
          <input
            type="text"
            className="form-control mb-2"
            name="country"
            placeholder="Country"
            value={searchTerms.country}
            onChange={handleSearchChange}
          />
        </div>

        <div className="col-md-4">
          <input
            type="text"
            className="form-control mb-2"
            name="state"
            placeholder="State"
            value={searchTerms.state}
            onChange={handleSearchChange}
          />
        </div>

        <div className="col-md-4">
          <button type="submit" className="btn btn-primary">Search</button>
        </div>
      </form>

      {isLoading && <div className="text-center"><p>Loading...</p></div>}
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}

      <ul className="list-group">
        {businesses.length > 0 ? businesses.map(business => (
          <li key={business.id} className="list-group-item">
            <h3>Name: {business.name}</h3>
            <p>Description: {business.description}</p>
            <p>State: {business.state}</p>
            <p>Country: {business.country}</p>
            {expandedId === business.id ? (
              <>
                {business.website && <p>Website: <a href={business.website} target="_blank" rel="noopener noreferrer" onClick={() => handleExternalClick(business.id, 'website')}>Visit Website</a></p>}
                {business.facebook && <p>Facebook: <a href={business.facebook} target="_blank" rel="noopener noreferrer" onClick={() => handleExternalClick(business.id, 'facebook')}>Facebook</a></p>}
                {business.instagram && <p>Instagram: <a href={business.instagram} target="_blank" rel="noopener noreferrer" onClick={() => handleExternalClick(business.id, 'instagram')}>Instagram</a></p>}
                <button onClick={() => toggleDetails(business.id)} className="btn btn-primary">View Less</button>
              </>
            ) : (
              <button onClick={() => toggleDetails(business.id)} className="btn btn-primary">View More</button>
            )}
          </li>
        )) : hasSearched && !isLoading && <p>No businesses found. Try a different search.</p>}
      </ul>

      {totalPages > 1 && (
        <nav>
          <ul className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
              <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                <button onClick={() => searchBusinesses(page)} className="page-link">
                  {page}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}

      {/* Modal for Keywords */}
      <div className="modal fade" id="infoModalKeywords" tabIndex="-1" aria-labelledby="infoModalKeywordsLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
            <h5               className="modal-title" id="infoModalKeywordsLabel">Information</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Search by exact keywords by selecting the checkbox.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Name */}
      <div className="modal fade" id="infoModalName" tabIndex="-1" aria-labelledby="infoModalNameLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="infoModalNameLabel">Information</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Search by exact business name by selecting the checkbox.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default BusinessListings;
